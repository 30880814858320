import { render, staticRenderFns } from "./Informe60Anios.vue?vue&type=template&id=7ac96708&scoped=true"
import script from "./Informe60Anios.vue?vue&type=script&lang=js"
export * from "./Informe60Anios.vue?vue&type=script&lang=js"
import style0 from "./Informe60Anios.vue?vue&type=style&index=0&id=7ac96708&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac96708",
  null
  
)

export default component.exports